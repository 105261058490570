import * as React from "react";
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { graphql, Link, StaticQuery } from "gatsby";
import moment from "moment-timezone";
import styled from "styled-components";
import { Page } from "../components/Page";
import DefaultLayout from "../layouts/default";
import { EventApi, EventAttributes } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";

import EventData from "../components/Events/EventData";
import Grid75 from "../components/Grid.tsx/Grid75";
import GreyBox from "../components/GreyBox";
import { InhaltElem } from "../components/Text/SmallerInhalt";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import Events from "../components/Events";
import SmallerMainTitle from "../components/Text/SmallerMainTitle";
import SectionTitle from "../components/Text/SectionTitle";
import Sponsors from "../components/Sponsors";
import Button from "../components/Buttons/Button";
import EventDates from "../components/Events/EventDates";

const SoldOut = styled.div`
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
`;

interface EventTemplateProps {
  pageContext: {
    attributes: EventAttributes;
    id: string;
  };
}

const api = new EventApi(defaultAPIConfig());

const EventTemplate: React.FC<EventTemplateProps> = (props) => {
  const [tickets, setTickets] = useState([]);
  // All Data which can be prepared at build time
  const {
    pageContext: {
      attributes: {
        description,
        date,
        labels,
        freeText: { text },
        ticketing: { ticketCategories },
      },
      id,
    },
  } = props;

  // All realtime data (ticket availability, ..)
  useEffect(() => {
    const fetchEvent = async () => {
      const event = await api.eventIdGet({ id: parseInt(id, 10) });
      if (event.data?.attributes?.ticketing?.ticketCategories) {
        // @ts-ignore
        setTickets(event.data.attributes.ticketing.ticketCategories);
      }
    };

    fetchEvent();
  }, []);

  const eventName = props.pageContext.attributes.agenda[0].name || "";
  const eventType = props.pageContext.attributes.type || "";
  let eventPlace = "";
  if (props.pageContext.attributes.labels?.length) {
    eventPlace = props.pageContext.attributes.labels[0].name;
  }
  const eventDate = props.pageContext.attributes.date || "";
  const eventEndDate = props.pageContext.attributes.end || "";
  let isAvailable = 0;
  tickets.map((ticket) => {
    if (ticket.available) {
      isAvailable++;
    }
  });

  const closingTime = moment(
    props.pageContext.attributes.ticketing?.closingTime
  );

  // console.log("event reservations?", props.pageContext.attributes.reservations);
  return (
    <DefaultLayout siteSlug={date?.toString()}>
      <Helmet>
        <title>{eventName} - STEP</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={description} />
      </Helmet>
      <Page>
        <ContentWrapper>
          <EventData
            type={eventType}
            date={eventDate}
            endDate={eventEndDate}
            relatedEvents={props.pageContext.relationships.events.data}
            place={eventPlace}
            showtime={props.pageContext.attributes.showtime}
          />

          <SmallerMainTitle color={eventType}>{eventName}</SmallerMainTitle>

          <Grid75>
            <InhaltElem>
              {description && (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              )}

              <Sponsors labels={labels} />
            </InhaltElem>

            <GreyBox>
              <InhaltElem>
                <b>Date</b>
                <br />
                <EventDates
                  relatedEvents={props.pageContext.relationships.events.data}
                  date={eventDate}
                  showtime={props.pageContext.attributes.showtime}
                  end={eventEndDate}
                />
                <br />
                <b>Venue</b>
                <br />
                {props.pageContext.relationships.location.data.attributes.name}
                <br />
                {
                  props.pageContext.relationships.location.data.attributes
                    .address
                }
                <br />
                {
                  props.pageContext.relationships.location.data.attributes.zip
                }{" "}
                {props.pageContext.relationships.location.data.attributes.city}
                <br />
                {
                  props.pageContext.relationships.location.data.attributes
                    .country
                }
                <br />
                {props.pageContext.relationships.location.data.attributes
                  .url !== "" ? (
                  <>
                    <a
                      target="_blank"
                      href={
                        props.pageContext.relationships.location.data.attributes
                          .url
                      }
                      rel="noreferrer"
                    >
                      link to venue
                    </a>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                <br />
                {(props.pageContext.attributes.reservations?.active ||
                  props.pageContext.attributes.ticketing?.active) && (
                  <>
                    <b>Registration by</b>
                    <br />
                    {moment(props.pageContext.attributes.ticketing?.closingTime)
                      .tz("Europe/Zurich")
                      .format("dddd, DD MMMM YYYY")
                      .toUpperCase()}
                    <br />
                    <br />
                  </>
                )}
                {text && (
                  <>
                    <b>CPD</b>
                    <br />
                    {text}
                    <br />
                    <br />
                  </>
                )}
                <b>Fee</b>
                <br />
                {tickets &&
                  tickets.map((ticket) => (
                    <div>
                      {ticket.title}{" "}
                      {ticket.admission == "0.00" ? (
                        <span>free of charge</span>
                      ) : (
                        `${ticket.admission} CHF`
                      )}{" "}
                      {ticket.info}{" "}
                    </div>
                  ))}
                {props.pageContext.attributes.reservations?.active &&
                  closingTime.isAfter(new Date()) && (
                    <Button
                      href={`/event/${id}/reservation/`}
                      text="RESERVATION"
                    />
                  )}
                {(props.pageContext.attributes.reservations?.active ||
                  props.pageContext.attributes.ticketing?.active) && (
                  <>
                    {props.pageContext.attributes.ticketing?.active && (
                      <>
                        {closingTime.isAfter(new Date()) ? (
                          <>
                            {isAvailable ? (
                              <Button
                                href={`/event/${id}/booking/`}
                                color={eventType}
                                text="REGISTER NOW"
                              />
                            ) : (
                              <>{/* <SoldOut>[sold out]</SoldOut> */}</>
                            )}
                          </>
                        ) : (
                          <>
                            {/** <SoldOut>[registration already closed]</SoldOut>  */}
                          </>
                        )}
                      </>
                    )}
                    <br />
                  </>
                )}
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.pageContext.attributes.shortDescription,
                  }}
                />
              </InhaltElem>
            </GreyBox>
          </Grid75>

          <StaticQuery
            query={graphql`
              query moreEvents {
                allEvent {
                  nodes {
                    eventId
                    relationships {
                      events {
                        data {
                          id
                          attributes {
                            date
                          }
                        }
                      }
                    }
                    attributes {
                      admission
                      labels {
                        name
                      }
                      agenda {
                        name
                      }
                      date(locale: "de")
                      description
                      externalTicketAgency {
                        name
                        url
                      }
                      freeText {
                        text
                        title
                      }
                      headline
                      masterEventId
                      locationId
                      shortDescription
                      showtime(locale: "de")
                      ticketing {
                        active
                        ticketCategories {
                          admission
                          available
                          info
                          ticketCategoryId
                          title
                        }
                        ticketsAvailable
                      }
                      tip
                      type
                    }
                  }
                }
              }
            `}
            render={(data: any) => (
              <>
                <SectionTitle>
                  MORE <b>STEP EVENTS</b>
                </SectionTitle>
                <Events events={data.allEvent.nodes} />
              </>
            )}
          />
        </ContentWrapper>
      </Page>
    </DefaultLayout>
  );
};

export default EventTemplate;
